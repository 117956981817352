import React from "react"
import { graphql } from "gatsby"

// Utility Functions
import { handleMenuItems } from "../utils/handleMenuItems"

// Custom components
import { Nav } from "../components/Nav"
import { Header } from "../components/Header"
import { Row } from "../components/Row"
import { Footer } from "../components/Footer"

// Styling page template
export default function TemplateStyling(props) {
  const {
    data: {
      wordpressPage: {
        slug,
        acf: {
          styling_headline: stylingHeadline,
          styling_hero_image: stylingHeroImage,
          styling_hero_headline: stylingHeroHeadline,
          styling_hero_copy: stylingHeroCopy,
          styling_featured_projects: stylingFeaturedProjects,
          styling_featured_projects_headline: stylingFeaturedProjectsHeadline,
        },
      },
      wordpressAcfOptions: {
        options: {
          sitewide_company_information_company_name: companyName,
          sitewide_company_information_company_twitter: companyTwitter,
          sitewide_company_information_company_linkedin: companyLinkedIn,
        },
      },
      allWordpressPage: { edges },
    },
  } = props

  return (
    <section className="white styling">
      <Nav slug={slug} menuItemsSorted={handleMenuItems({ items: edges })} />

      <Header headerHeadline={stylingHeadline}>
        <div className="feature">
          <div className="feature__container">
            <img className="feature__image" src={stylingHeroImage.localFile.childImageSharp.fluid.src} alt="Robert Idol Styling Design" />
          </div>
          <div className="feature__container feature__container--alt">
            <h3 className="feature__headline">{stylingHeroHeadline}</h3>
            <p className="feature__copy">{stylingHeroCopy}</p>
          </div>
        </div>
      </Header>

      <Row rowPage="styling" featuredProjs={stylingFeaturedProjects} headline={stylingFeaturedProjectsHeadline} />

      <Footer
        companyName={companyName}
        companyTwitter={companyTwitter}
        companyLinkedIn={companyLinkedIn}
        slug={slug}
        menuItemsSorted={handleMenuItems({ items: edges })}
      />
    </section>
  )
}

export const pageQuery = graphql`
  query {
    wordpressPage(title: { eq: "Styling &#038; Branding" }) {
      slug
      acf {
        styling_headline
        styling_hero_image {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        styling_hero_headline
        styling_hero_copy
        styling_featured_projects_headline
        styling_featured_projects {
          styling_featured_projects_image {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          styling_featured_projects_name
          styling_featured_projects_type
          styling_featured_projects_page_link
        }
      }
    }
    wordpressAcfOptions {
      options {
        sitewide_company_information_company_name
        sitewide_company_information_company_twitter
        sitewide_company_information_company_linkedin
      }
    }
    allWordpressPage(filter: { wordpress_parent: { eq: 0 } }) {
      edges {
        node {
          title
          menu_order
          slug
        }
      }
    }
  }
`
